import {createSlice} from "@reduxjs/toolkit";

import axios from "../config/Axios";
import {APP_USER_ID} from "../config/Constants";
import {countersActions} from "./counters";
import {fireFbEvent} from "../helpers/FbPixel";
import {fireTikTokEvent} from "../helpers/TikTokPixel";
import {removeCustomMode} from "../helpers/DeveloperMode";

const initialState = {
    fetchOnInit: false,
    isMobile: true,
    forceHideMobileMenu: false,
    notificationPermissionGranted: false,
    user: null
}

const authSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        setMobileMenu(state, action) {
            state.forceHideMobileMenu = action.payload.hide;
        },
        setMobile(state, action) {
            state.isMobile = action.payload.isMobile;
        },
        set(state, action) {
            state.fetchOnInit = true;
            state.user = action.payload.user;
        },
        setAsFetched(state) {
            state.fetchOnInit = true;
        },
        update(state, action) {
            state.user = {
                ...state.user,
                ...action.payload
            };
        },
        removeNotification(state, action) {
            state.user.additional.notifications = state.user.additional.notifications.filter(item => item.id !== action.payload.id)
        },
        grantPermission(state) {
            state.notificationPermissionGranted = true;
        }
    }
});

const fbEventOnRegistration = ev => {
    if (ev === 'registration') {
       fireFbEvent('SignUp');
       fireTikTokEvent('SignUp');
    }
}

export const loginWithFacebook = (token, successCallback, failCallback) => {
    return dispatch => {
        axios.post('auth/fb', {accessToken: token})
            .then(r => {
                dispatch(loginUser(r.data.user.id, successCallback, failCallback));
                fbEventOnRegistration(r.data.eventStatus);
            }).catch(error => {
            failCallback();
            alert('Cannot connect with facebook!');
        });
    }
}

export const loginWithGoogle = (data, successCallback, failCallback) => {
    return dispatch => {
        axios.post('auth/google', data)
            .then(r => {
                dispatch(loginUser(r.data.user.id, successCallback, failCallback));
                fbEventOnRegistration(r.data.eventStatus);
            }).catch(error => {
            failCallback();
            alert('Cannot connect with google-login!');
        });
    }
}

export const loginWithCredentials = (data, successCallback, failCallback) => {
    return dispatch => {
        axios.post('auth/login', data)
            .then(r => {
                if (r.data.user) {
                    dispatch(loginUser(r.data.user.id, successCallback, failCallback));
                }
            }).catch(error => {
                const errorMsg = (error.response.status === 400) ? 'Wrong email or password' : 'Internet connection error';
                failCallback(errorMsg);
            });
    }
}

export const checkLoggedUserInfo = () => {
    return async (dispatch) => {
        let userId = localStorage.getItem(APP_USER_ID);

        if (userId === null) {
            dispatch(authActions.setAsFetched());
        }
        else {
            const userInfo = await axios.get('/user/' + userId + '?addCounters=1&addNotifications=1&updateOnline=1&prices=1&addUserNotifications=1');
            if (userInfo && userInfo.data) {
                dispatch(authActions.set({user: userInfo.data}));
                dispatch(countersActions.updateAllCounters(userInfo.data.counters));
            }
        }
    }
}

export const loginUser = (userId, successCallback, failCallback) => {
    return async dispatch => {
        localStorage.setItem(APP_USER_ID, userId);

        await axios.get('user/'+userId+'?addCounters=1&addNotifications=1&updateOnline=1&prices=1&addUserNotifications=1')
            .then(async r => {
                if (typeof successCallback === "function") {
                    successCallback();
                }
                dispatch(authActions.set({user: r.data}));
                dispatch(countersActions.updateAllCounters(r.data.counters));
            }).catch(error => {
            if (typeof failCallback === "function") {
                failCallback('Something went wrong')
            }
        });
    }
}

export const updateUser = (userId, data) => {
    return dispatch => {
        axios.post('user/'+userId+'?addCounters=1&addNotifications=1&updateOnline=1&prices=1&addUserNotifications=1', data)
            .then(r => {
                dispatch(authActions.set({user: r.data}));
                dispatch(countersActions.updateAllCounters(r.data.counters));
            }).catch(error => {
            //dispatch(logoutUser());
            //dispatch(setAsFetched());
        });
    }
}

export const refetchUser = (userId) => {
    return dispatch => {
        axios.get('user/'+userId+'?addCounters=1&addNotifications=1&updateOnline=1&prices=1&addUserNotifications=1')
            .then(r => {
                dispatch(authActions.set({user: r.data}));
                dispatch(countersActions.updateAllCounters(r.data.counters));
            });
    }
}

export const logoutUser = (userId) => {
    return dispatch => {
        axios.get('auth/logout').then(async r => {
        }).finally(() => {

            dispatch(authActions.set({user: null}));
            removeCustomMode();
            localStorage.removeItem(APP_USER_ID);
        });
    }
}

export const removeUser = (userId) => {
    return dispatch => {
        axios.get('user/'+userId+'/remove').then(r => {
            dispatch(authActions.set({user: null}));
            localStorage.removeItem(APP_USER_ID);
            window.location = '/';
        }).catch(error => {
            //dispatch(authActions.set({user: null}));
            //localStorage.removeItem(APP_USER_ID);
        });
    }
}

export const authActions = authSlice.actions;
export default authSlice.reducer;
