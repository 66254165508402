import TiktokPixel from 'tiktok-pixel';
import {tiktokPixelId} from "../config/Config";

export const initTikTokPixel = () => {
    if (!tiktokPixelId) {
        return;
    }

    TiktokPixel.init(tiktokPixelId);
    TiktokPixel.pageView();
}

export const fireTikTokEvent = (eventName, data) => {
    if (!tiktokPixelId) {
        return;
    }

    if (eventName === 'OpenRegistration') {
        TiktokPixel.track('ViewContent', {content_id: '1', content_name: 'Registration Popup', content_category: 'Registration'});
    }

    if (eventName === 'SignUp') {
        TiktokPixel.track('CompleteRegistration', {content_id: '2', content_name: 'Complete SignUp', content_category: 'SignUp'});
    }

    if (eventName === 'Purchase') {
        TiktokPixel.track('CompletePayment', {content_id: '3', content_name: 'Complete Payment', content_category: 'Purchase', value: data.amount, currency: data.currency});
    }
}
