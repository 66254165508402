import {createSlice} from "@reduxjs/toolkit";

const modalList = {
    login: false,
    registration: false,
    passwordReminder: false,
    preferences: false,
    membership: false,
    membershipFemale: false,
    membershipFemaleDiamondReminder: false,
    vipUpgrade: false,
    vipDiscounted: false,
    verify: false,
    verifyRenew: false,
    verifyWeekly: false,
    verifyBlocked: false,
    photoUploadInfo: false,
    photoLimit: false,
    tgVideo: false,
    addTrip: false,
    location: false,
    age: false,
    contentAgreement: false,
    notificationMessage: false,
    paymentStripe: false,
    emailValidation: false,
    deleteView: false
};

const initialState = {
    isOpened: false,
    disabledClose: false,
    modal: {...modalList},
    data: null
}

const modalSlice = createSlice({
    name: 'modal',
    initialState: initialState,
    reducers: {
        close(state) {
            state.isOpened = false;
            state.disabledClose = false;
            state.modal = {...modalList};
        },
        open(state, action) {
            const list = {...modalList};
            if (typeof action.payload.name !== "undefined") {
                list[action.payload.name] = true;
            }

            state.isOpened = true;
            state.disabledClose = (action.payload.disabled) ? true : false;
            state.modal = list;
            state.data = action.payload.data ?? null
        }
    }
});

export const modalActions = modalSlice.actions;
export default modalSlice.reducer;
