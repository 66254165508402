import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import {Provider} from  'react-redux';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";


import './index.css';
import './styles/Common.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

import store from './store/index';

// Error logger only in production mode
if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
        dsn: "https://a4051d71ce5a4449ae5f8bc2fcdbb366@o529142.ingest.sentry.io/5647075",
        release: process.env.REACT_APP_NAME + "@" + process.env.REACT_APP_VERSION,
        integrations: [new Integrations.BrowserTracing()],
        ignoreErrors: [
            ///Failed to execute 'setAppBadge' on 'Navigator'/,
            'Failed to execute \'setAppBadge\' on \'Navigator\': Illegal invocation',
            'Network Error',
            'Loading CSS chunk',
            'Cannot read properties of null (reading \'classList\')'
        ],

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 0.001,
    });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
