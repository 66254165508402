export const rtmDomain = process.env.REACT_APP_RTM_DOMAIN;
export const stripePublic = process.env.REACT_APP_STRIPE_PUBLIC;
export const googleAnalyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
export const googleRecaptcheyKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY;
export const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID;
export const facebookPixelId = process.env.REACT_APP_PIXEL_ID;
export const tiktokPixelId = process.env.REACT_APP_TIKTOK_PIXEL_ID;
export const facebookAppVersion = 'v14.0';
export const facebookScope = 'public_profile,email';
export const googleAutocompleteProxyDomain = 'https://d3u8lr4sl0c5f3.cloudfront.net';
export const googlePlayURL = 'https://play.google.com/store/apps/details?id=com.travelgirls';
export const googlePlayURLTf = 'https://play.google.com/store/apps/details?id=com.travelfriend';
export const vapidKey = 'BAnPdkdjklb21xDhduSIaq81B91kEOycyEjRZHystin7w2zxAM5nmdw3T8G5arFxT4kHYlHZLehG8YhzhhQDA3M';
export const firebaseConfig = {
    apiKey: "AIzaSyD_cki-5cL_e1jj8W9wJNWrxAbU9YO1YQM",
    authDomain: "web-push-service-1f497.firebaseapp.com",
    projectId: "web-push-service-1f497",
    storageBucket: "web-push-service-1f497.appspot.com",
    messagingSenderId: "918560386393",
    appId: "1:918560386393:web:f7aa86c532744be9c63048",
    measurementId: googleAnalyticsId // "G-HM4JC5P6BD"
};

export const countryList = {
    AF: "Afghanistan",
    AX: "Aland Islands",
    AL: "Albania",
    DZ: "Algeria",
    AS: "American Samoa",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguilla",
    AQ: "Antarctica",
    AG: "Antigua and Barbuda",
    AR: "Argentina",
    AM: "Armenia",
    AW: "Aruba",
    AU: "Australia",
    AT: "Austria",
    AZ: "Azerbaijan",
    BS: "Bahamas",
    BH: "Bahrain",
    BD: "Bangladesh",
    BB: "Barbados",
    BY: "Belarus",
    BE: "Belgium",
    BZ: "Belize",
    BJ: "Benin",
    BM: "Bermuda",
    BT: "Bhutan",
    BO: "Bolivia",
    BQ: "Bonaire,Sint Eustatius and Saba",
    BA: "Bosnia and Herzegovina",
    BW: "Botswana",
    BV: "Bouvet Island",
    BR: "Brazil",
    IO: "British Indian Ocean Territory",
    VG: "British Virgin Islands",
    BN: "Brunei",
    BG: "Bulgaria",
    BF: "Burkina Faso",
    BI: "Burundi",
    KH: "Cambodia",
    CM: "Cameroon",
    CA: "Canada",
    CV: "Cape Verde",
    KY: "Cayman Islands",
    CF: "Central African Republic",
    TD: "Chad",
    CL: "Chile",
    CN: "China",
    CX: "Christmas Island",
    CC: "Cocos Islands",
    CO: "Colombia",
    KM: "Comoros",
    CG: "Congo-Brazzaville",
    CK: "Cook Islands",
    CR: "Costa Rica",
    CI: "Côte d'Ivoire",
    HR: "Croatia",
    CU: "Cuba",
    CW: "Curacao",
    CY: "Cyprus",
    CZ: "Czech Republic",
    CD: "Democratic Republic of the Congo",
    DK: "Denmark",
    DJ: "Djibouti",
    DM: "Dominica",
    DO: "Dominican Republic",
    TL: "East Timor",
    EC: "Ecuador",
    EG: "Egypt",
    SV: "El Salvador",
    GQ: "Equatorial Guinea",
    ER: "Eritrea",
    EE: "Estonia",
    ET: "Ethiopia",
    FK: "Falkland Islands (Islas Malvinas)",
    FO: "Faroe Islands",
    FJ: "Fiji",
    FI: "Finland",
    FR: "France",
    GF: "French Guiana",
    PF: "French Polynesia",
    TF: "French Southern Territories",
    GA: "Gabon",
    GM: "Gambia",
    GE: "Georgia",
    DE: "Germany",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Greece",
    GL: "Greenland",
    GD: "Grenada",
    GP: "Guadeloupe",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernsey",
    GN: "Guinea",
    GW: "Guinea-Bissau",
    GY: "Guyana",
    HT: "Haiti",
    HM: "Heard Island and McDonald Islands",
    HN: "Honduras",
    HK: "Hong Kong",
    HU: "Hungary",
    IS: "Iceland",
    IN: "India",
    ID: "Indonesia",
    IR: "Iran",
    IQ: "Iraq",
    IE: "Ireland",
    IM: "Isle of Man",
    IL: "Israel",
    IT: "Italy",
    JM: "Jamaica",
    JP: "Japan",
    JE: "Jersey",
    JO: "Jordan",
    KZ: "Kazakhstan",
    KE: "Kenya",
    KI: "Kiribati",
    XK: "Kosovo",
    KW: "Kuwait",
    KG: "Kyrgyzstan",
    LA: "Laos",
    LV: "Latvia",
    LB: "Lebanon",
    LS: "Lesotho",
    LR: "Liberia",
    LY: "Libya",
    LI: "Liechtenstein",
    LT: "Lithuania",
    LU: "Luxembourg",
    MO: "Macao",
    MK: "North Macedonia",
    MG: "Madagascar",
    MW: "Malawi",
    MY: "Malaysia",
    MV: "Maldives",
    ML: "Mali",
    MT: "Malta",
    MH: "Marshall Islands",
    MQ: "Martinique",
    MR: "Mauritania",
    MU: "Mauritius",
    YT: "Mayotte",
    MX: "Mexico",
    FM: "Micronesia",
    MD: "Moldova",
    MC: "Monaco",
    MN: "Mongolia",
    ME: "Montenegro",
    MS: "Montserrat",
    MA: "Morocco",
    MZ: "Mozambique",
    MM: "Myanmar",
    NA: "Namibia",
    NR: "Nauru",
    NP: "Nepal",
    NL: "Netherlands",
    NC: "New Caledonia",
    NZ: "New Zealand",
    NI: "Nicaragua",
    NE: "Niger",
    NG: "Nigeria",
    NU: "Niue",
    NF: "Norfolk Island",
    KR: "South Korea",
    MP: "Northern Mariana Islands",
    NO: "Norway",
    OM: "Oman",
    PK: "Pakistan",
    PW: "Palau",
    PS: "Palestinian Territories",
    PA: "Panama",
    PG: "Papua New Guinea",
    PY: "Paraguay",
    PE: "Peru",
    PH: "Philippines",
    PN: "Pitcairn Islands",
    PL: "Poland",
    PT: "Portugal",
    PR: "Puerto Rico",
    QA: "Qatar",
    RE: "Reunion",
    RO: "Romania",
    RU: "Russia",
    RW: "Rwanda",
    BL: "Saint Barthélemy",
    SH: "Saint Helena",
    KN: "Saint Kitts and Nevis",
    LC: "Saint Lucia",
    MF: "Saint Martin",
    PM: "Saint Pierre and Miquelon",
    VC: "Saint Vincent and the Grenadines",
    SX: "Sint Maarten",
    WS: "Samoa",
    SM: "San Marino",
    ST: "São Tomé and Príncipe",
    SA: "Saudi Arabia",
    SN: "Senegal",
    RS: "Serbia",
    SC: "Seychelles",
    SL: "Sierra Leone",
    SG: "Singapore",
    SK: "Slovakia",
    SI: "Slovenia",
    SB: "Solomon Islands",
    SO: "Somalia",
    ZA: "South Africa",
    GS: "South Georgia and the South Sandwich Islands",
    KP: "North Korea",
    SS: "South Sudan",
    ES: "Spain",
    LK: "Sri Lanka",
    SD: "Sudan",
    SR: "Suriname",
    SJ: "Svalbard and Jan Mayen",
    SZ: "Swaziland",
    SE: "Sweden",
    CH: "Switzerland",
    SY: "Syria",
    TW: "Taiwan",
    TJ: "Tajikistan",
    TZ: "Tanzania",
    TH: "Thailand",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidad and Tobago",
    TN: "Tunisia",
    TR: "Türkiye",
    TM: "Turkmenistan",
    TC: "Turks and Caicos Islands",
    TV: "Tuvalu",
    VI: "U.S. Virgin Islands",
    UG: "Uganda",
    UA: "Ukraine",
    AE: "United Arab Emirates",
    GB: "United Kingdom",
    US: "United States",
    UM: "United States Minor Outlying Islands",
    UY: "Uruguay",
    UZ: "Uzbekistan",
    VU: "Vanuatu",
    VA: "Vatican City",
    VE: "Venezuela",
    VN: "Vietnam",
    WF: "Wallis and Futuna",
    EH: "Western Sahara",
    YE: "Yemen",
    ZM: "Zambia",
    ZW: "Zimbabwe"
};
export const nationalityList = [
    {id: 1, value: 'Afghan'},
    {id: 2, value: 'Albanian'},
    {id: 3, value: 'Algerian'},
    {id: 4, value: 'American'},
    {id: 5, value: 'Andorran'},
    {id: 6, value: 'Angolan'},
    {id: 7, value: 'Antiguan'},
    {id: 8, value: 'Argentinian'},
    {id: 9, value: 'Armenian'},
    {id: 10, value: 'Aruban'},
    {id: 11, value: 'Australian'},
    {id: 12, value: 'Austrian'},
    {id: 13, value: 'Azerbaijani'},
    {id: 14, value: 'Bahamian'},
    {id: 15, value: 'Bahraini'},
    {id: 16, value: 'Bangladeshi'},
    {id: 17, value: 'Barbadian'},
    {id: 18, value: 'Belarusian'},
    {id: 19, value: 'Belgian'},
    {id: 20, value: 'Belizian'},
    {id: 21, value: 'Beninese'},
    {id: 22, value: 'Bermudian'},
    {id: 23, value: 'Bhutanese'},
    {id: 24, value: 'Bolivian'},
    {id: 25, value: 'Bosnian'},
    {id: 26, value: 'Botswanan'},
    {id: 27, value: 'Brazilian'},
    {id: 28, value: 'British'},
    {id: 29, value: 'Bruneian'},
    {id: 30, value: 'Bulgarian'},
    {id: 31, value: 'Burkinese'},
    {id: 32, value: 'Burmese'},
    {id: 33, value: 'Burundian'},
    {id: 34, value: 'Cambodian'},
    {id: 35, value: 'Cameroonian'},
    {id: 36, value: 'Canadian'},
    {id: 37, value: 'Cape Verdean'},
    {id: 38, value: 'Chadian'},
    {id: 39, value: 'Chilean'},
    {id: 40, value: 'Chinese'},
    {id: 41, value: 'Colombian'},
    {id: 42, value: 'Congolese'},
    {id: 43, value: 'Costa Rican'},
    {id: 44, value: 'Croatian'},
    {id: 45, value: 'Cuban'},
    {id: 46, value: 'Cypriot'},
    {id: 47, value: 'Czech'},
    {id: 48, value: 'Danish'},
    {id: 49, value: 'Djiboutian'},
    {id: 50, value: 'Dominican'},
    {id: 51, value: 'Dutch'},
    {id: 52, value: 'Ecuadorean'},
    {id: 53, value: 'Egyptian'},
    {id: 54, value: 'Emirati'},
    {id: 55, value: 'Eritrean'},
    {id: 56, value: 'Estonian'},
    {id: 57, value: 'Ethiopian'},
    {id: 58, value: 'Fijian'},
    {id: 59, value: 'Finnish'},
    {id: 60, value: 'French'},
    {id: 61, value: 'Gabonese'},
    {id: 62, value: 'Gambian'},
    {id: 63, value: 'Georgian'},
    {id: 64, value: 'German'},
    {id: 65, value: 'Ghanaian'},
    {id: 66, value: 'Greek'},
    {id: 67, value: 'Grenadian'},
    {id: 68, value: 'Guatemalan'},
    {id: 69, value: 'Guinean'},
    {id: 70, value: 'Guyanese'},
    {id: 71, value: 'Haitian'},
    {id: 72, value: 'Honduran'},
    {id: 73, value: 'Hungarian'},
    {id: 74, value: 'Icelandic'},
    {id: 75, value: 'Indian'},
    {id: 76, value: 'Indonesian'},
    {id: 77, value: 'Iranian'},
    {id: 78, value: 'Iraqi'},
    {id: 79, value: 'Irish'},
    {id: 80, value: 'Italian'},
    {id: 81, value: 'Jamaican'},
    {id: 82, value: 'Japanese'},
    {id: 83, value: 'Jordanian'},
    {id: 84, value: 'Kazakh'},
    {id: 85, value: 'Kenyan'},
    {id: 86, value: 'Kuwaiti'},
    {id: 87, value: 'Laotian'},
    {id: 88, value: 'Latvian'},
    {id: 89, value: 'Lebanese'},
    {id: 90, value: 'Liberian'},
    {id: 91, value: 'Libyan'},
    {id: 92, value: 'Liechtensteiner'},
    {id: 93, value: 'Lithuanian'},
    {id: 94, value: 'Luxembourger'},
    {id: 95, value: 'Macedonian'},
    {id: 96, value: 'Madagascan'},
    {id: 97, value: 'Malawian'},
    {id: 98, value: 'Malaysian'},
    {id: 99, value: 'Maldivian'},
    {id: 100, value: 'Malian'},
    {id: 101, value: 'Maltese'},
    {id: 102, value: 'Mauritanian'},
    {id: 103, value: 'Mauritian'},
    {id: 104, value: 'Mexican'},
    {id: 105, value: 'Moldovan'},
    {id: 106, value: 'Monacan'},
    {id: 107, value: 'Mongolian'},
    {id: 108, value: 'Montenegrin'},
    {id: 109, value: 'Moroccan'},
    {id: 110, value: 'Mozambican'},
    {id: 111, value: 'Namibian'},
    {id: 112, value: 'Nepalese'},
    {id: 113, value: 'New Zealander'},
    {id: 114, value: 'Nicaraguan'},
    {id: 115, value: 'Nigerian'},
    {id: 116, value: 'Nigerien'},
    {id: 117, value: 'North Korean'},
    {id: 118, value: 'Norwegian'},
    {id: 119, value: 'Omani'},
    {id: 120, value: 'Pakistani'},
    {id: 121, value: 'Panamanian'},
    {id: 122, value: 'Paraguayan'},
    {id: 123, value: 'Peruvian'},
    {id: 124, value: 'Philippine'},
    {id: 125, value: 'Polish'},
    {id: 126, value: 'Portuguese'},
    {id: 127, value: 'Qatari'},
    {id: 128, value: 'Romanian'},
    {id: 129, value: 'Russian'},
    {id: 130, value: 'Rwandan'},
    {id: 131, value: 'Salvadorean'},
    {id: 132, value: 'Saudi Arabian'},
    {id: 133, value: 'Scottish'},
    {id: 134, value: 'Senegalese'},
    {id: 135, value: 'Serbian'},
    {id: 136, value: 'Seychellois'},
    {id: 137, value: 'Sierra Leonian'},
    {id: 138, value: 'Singaporean'},
    {id: 139, value: 'Slovak'},
    {id: 140, value: 'Slovenian'},
    {id: 141, value: 'Solomon Islander'},
    {id: 142, value: 'Somali'},
    {id: 143, value: 'South African'},
    {id: 144, value: 'South Korean'},
    {id: 145, value: 'Spanish'},
    {id: 146, value: 'Sri Lankan'},
    {id: 147, value: 'Sudanese'},
    {id: 148, value: 'Surinamese'},
    {id: 149, value: 'Swazi'},
    {id: 150, value: 'Swedish'},
    {id: 151, value: 'Swiss'},
    {id: 152, value: 'Syrian'},
    {id: 153, value: 'Taiwanese'},
    {id: 154, value: 'Tajik'},
    {id: 155, value: 'Tanzanian'},
    {id: 156, value: 'Thai'},
    {id: 157, value: 'Togolese'},
    {id: 158, value: 'Trinidadian'},
    {id: 159, value: 'Tunisian'},
    {id: 160, value: 'Turkish'},
    {id: 161, value: 'Turkmen'},
    {id: 162, value: 'Tuvaluan'},
    {id: 163, value: 'Ugandan'},
    {id: 164, value: 'Ukrainian'},
    {id: 165, value: 'Uruguayan'},
    {id: 166, value: 'Uzbek'},
    {id: 167, value: 'Vanuatuan'},
    {id: 168, value: 'Venezuelan'},
    {id: 169, value: 'Vietnamese'},
    {id: 170, value: 'Welsh'},
    {id: 171, value: 'Western Samoan'},
    {id: 172, value: 'Yemeni'},
    {id: 173, value: 'Yugoslav'},
    {id: 174, value: 'Zairean'},
    {id: 175, value: 'Zambian'},
    {id: 176, value: 'Zimbabwean'},
    {id: 177, value: 'Israeli'},
    {id: 178, value: 'Tai'},
    {id: 179, value: 'Myanmar'},
    {id: 180, value: 'Shan'},
    {id: 181, value: 'St. Vincent and the grenadines'},
    {id: 182, value: 'Palestinian'},
    {id: 183, value: 'Ivorian'},
    {id: 184, value: 'Kyrgyz'},
    {id: 185, value: 'Chechen'},
    {id: 186, value: 'Papua New Guinean'},
    {id: 187, value: 'English'},
    {id: 188, value: 'Buryat'},
    {id: 189, value: 'Saint Lucian'},
    {id: 190, value: 'Kyrgyzstani'},
    {id: 191, value: 'Puerto Rican'},
    {id: 192, value: 'Kurdish'}
];
export const eyeList = ['Brown', 'Blue', 'Green', 'Hazel', 'Gray', 'Amber', 'Other'];
export const hairList = ['Blond', 'Brown', 'Black', 'Red', 'Auburn', 'Grey', 'Other'];
export const bodyTypeList = ['Slim', 'Athletic', 'Average', 'Curvy', 'Heavy'];
export const languageList = {1: 'Arabic', 44: 'Armenian', 47: 'Azerbaijani',4: 'Belorussian', 49:'Bengali', 13: 'Bulgarian', 19: 'Catalan', 25: 'Chinese', 31: 'Croatian', 37: 'Czech', 2: 'Danish', 5: 'Dutch', 14: 'English', 50:'Esperanto', 20: 'Estonian', 26: 'Filipino', 32: 'Finnish', 38: 'French', 48: 'Georgian', 3: 'German', 6: 'Greek', 15: 'Hebrew', 21: 'Hindi', 27: 'Hungarian', 33: 'Icelandic', 39: 'Indonesian', 9: 'Italian', 7: 'Japanese', 16: 'Korean', 45: 'Kurdish', 22: 'Latvian', 28: 'Lithuanian', 46: 'Maltese', 43: 'Nepali', 34: 'Norwegian', 40: 'Persian', 10: 'Polish', 8: 'Portuguese', 17: 'Romanian', 23: 'Russian', 29: 'Serbian', 35: 'Slovak', 41: 'Slovenian', 11: 'Spanish', 12: 'Swedish', 18: 'Thai', 24: 'Turkish', 30: 'Ukrainian', 36: 'Urdu', 42: 'Vietnamese'};
export const lookingForList = ['Friends', 'Adventure', 'Soulmate', 'Job'];
export const mobileCodes = [
    {
        "id": 0,
        "key": "+0",
        "value": "None"
    },
    {
        "id": 1,
        "key": "+93",
        "value": "Afghanistan"
    },
    {
        "id": 2,
        "key": "+355",
        "value": "Albania"
    },
    {
        "id": 3,
        "key": "+213",
        "value": "Algeria"
    },
    {
        "id": 4,
        "key": "+376",
        "value": "Andorra"
    },
    {
        "id": 5,
        "key": "+244",
        "value": "Angola"
    },
    {
        "id": 6,
        "key": "+1268",
        "value": "Antigua and Barbuda"
    },
    {
        "id": 7,
        "key": "+54",
        "value": "Argentina"
    },
    {
        "id": 8,
        "key": "+374",
        "value": "Armenia"
    },
    {
        "id": 9,
        "key": "+297",
        "value": "Aruba"
    },
    {
        "id": 10,
        "key": "+61",
        "value": "Australia"
    },
    {
        "id": 11,
        "key": "+43",
        "value": "Austria"
    },
    {
        "id": 12,
        "key": "+994",
        "value": "Azerbaijan"
    },
    {
        "id": 13,
        "key": "+1242",
        "value": "Bahamas"
    },
    {
        "id": 14,
        "key": "+973",
        "value": "Bahrain"
    },
    {
        "id": 15,
        "key": "+880",
        "value": "Bangladesh"
    },
    {
        "id": 16,
        "key": "+1246",
        "value": "Barbados"
    },
    {
        "id": 17,
        "key": "+375",
        "value": "Belarus"
    },
    {
        "id": 18,
        "key": "+32",
        "value": "Belgium"
    },
    {
        "id": 19,
        "key": "+501",
        "value": "Belize"
    },
    {
        "id": 20,
        "key": "+229",
        "value": "Benin"
    },
    {
        "id": 21,
        "key": "+1441",
        "value": "Bermuda"
    },
    {
        "id": 22,
        "key": "+975",
        "value": "Bhutan"
    },
    {
        "id": 23,
        "key": "+591",
        "value": "Bolivia"
    },
    {
        "id": 24,
        "key": "+387",
        "value": "Bosnia and Herzegovina"
    },
    {
        "id": 25,
        "key": "+267",
        "value": "Botswana"
    },
    {
        "id": 26,
        "key": "+55",
        "value": "Brazil"
    },
    {
        "id": 27,
        "key": "+673",
        "value": "Brunei"
    },
    {
        "id": 28,
        "key": "+359",
        "value": "Bulgaria"
    },
    {
        "id": 29,
        "key": "+226",
        "value": "Burkina Faso"
    },
    {
        "id": 30,
        "key": "+257",
        "value": "Burundi"
    },
    {
        "id": 31,
        "key": "+855",
        "value": "Cambodia"
    },
    {
        "id": 32,
        "key": "+237",
        "value": "Cameroon"
    },
    {
        "id": 33,
        "key": "+1",
        "value": "United States"
    },
    {
        "id": 34,
        "key": "+238",
        "value": "Cape Verde"
    },
    {
        "id": 35,
        "key": "+1345",
        "value": "Cayman Islands"
    },
    {
        "id": 36,
        "key": "+236",
        "value": "Central African Republic"
    },
    {
        "id": 37,
        "key": "+235",
        "value": "Chad"
    },
    {
        "id": 38,
        "key": "+56",
        "value": "Chile"
    },
    {
        "id": 39,
        "key": "+86",
        "value": "China"
    },
    {
        "id": 40,
        "key": "+57",
        "value": "Colombia"
    },
    {
        "id": 41,
        "key": "+269",
        "value": "Comoros"
    },
    {
        "id": 42,
        "key": "+242",
        "value": "Congo Brazzaville"
    },
    {
        "id": 43,
        "key": "+243",
        "value": "Congo"
    },
    {
        "id": 44,
        "key": "+682",
        "value": "Cook Islands"
    },
    {
        "id": 45,
        "key": "+506",
        "value": "Costa Rica"
    },
    {
        "id": 46,
        "key": "+225",
        "value": "Cote D Ivoire"
    },
    {
        "id": 47,
        "key": "+385",
        "value": "Croatia"
    },
    {
        "id": 48,
        "key": "+53",
        "value": "Cuba"
    },
    {
        "id": 49,
        "key": "+357",
        "value": "Cyprus"
    },
    {
        "id": 50,
        "key": "+420",
        "value": "Czech Republic"
    },
    {
        "id": 51,
        "key": "+45",
        "value": "Denmark"
    },
    {
        "id": 52,
        "key": "+253",
        "value": "Djibouti"
    },
    {
        "id": 53,
        "key": "+1767",
        "value": "Dominica"
    },
    {
        "id": 54,
        "key": "+18",
        "value": "Dominican Republic"
    },
    {
        "id": 55,
        "key": "+593",
        "value": "Ecuador"
    },
    {
        "id": 56,
        "key": "+20",
        "value": "Egypt"
    },
    {
        "id": 57,
        "key": "+503",
        "value": "El Salvador"
    },
    {
        "id": 58,
        "key": "+240",
        "value": "Equatorial Guinea"
    },
    {
        "id": 59,
        "key": "+291",
        "value": "Eritrea"
    },
    {
        "id": 60,
        "key": "+372",
        "value": "Estonia"
    },
    {
        "id": 61,
        "key": "+251",
        "value": "Ethiopia"
    },
    {
        "id": 62,
        "key": "+298",
        "value": "Faroe Islands"
    },
    {
        "id": 63,
        "key": "+679",
        "value": "Fiji"
    },
    {
        "id": 64,
        "key": "+358",
        "value": "Finland"
    },
    {
        "id": 65,
        "key": "+33",
        "value": "France"
    },
    {
        "id": 66,
        "key": "+241",
        "value": "Gabon"
    },
    {
        "id": 67,
        "key": "+220",
        "value": "Gambia"
    },
    {
        "id": 68,
        "key": "+995",
        "value": "Georgia"
    },
    {
        "id": 69,
        "key": "+49",
        "value": "Germany"
    },
    {
        "id": 70,
        "key": "+233",
        "value": "Ghana"
    },
    {
        "id": 71,
        "key": "+350",
        "value": "Gibraltar"
    },
    {
        "id": 72,
        "key": "+30",
        "value": "Greece"
    },
    {
        "id": 73,
        "key": "+299",
        "value": "Greenland"
    },
    {
        "id": 74,
        "key": "+1473",
        "value": "Grenada"
    },
    {
        "id": 75,
        "key": "+590",
        "value": "Guadeloupe"
    },
    {
        "id": 76,
        "key": "+1671",
        "value": "Guam"
    },
    {
        "id": 77,
        "key": "+502",
        "value": "Guatemala"
    },
    {
        "id": 78,
        "key": "+224",
        "value": "Guinea"
    },
    {
        "id": 79,
        "key": "+245",
        "value": "Guinea Bissau"
    },
    {
        "id": 80,
        "key": "+592",
        "value": "Guyana"
    },
    {
        "id": 81,
        "key": "+509",
        "value": "Haiti"
    },
    {
        "id": 82,
        "key": "+504",
        "value": "Honduras"
    },
    {
        "id": 83,
        "key": "+852",
        "value": "Hong Kong"
    },
    {
        "id": 84,
        "key": "+36",
        "value": "Hungary"
    },
    {
        "id": 85,
        "key": "+354",
        "value": "Iceland"
    },
    {
        "id": 86,
        "key": "+91",
        "value": "India"
    },
    {
        "id": 87,
        "key": "+62",
        "value": "Indonesia"
    },
    {
        "id": 88,
        "key": "+98",
        "value": "Iran"
    },
    {
        "id": 89,
        "key": "+964",
        "value": "Iraq"
    },
    {
        "id": 90,
        "key": "+353",
        "value": "Ireland"
    },
    {
        "id": 91,
        "key": "+972",
        "value": "Israel"
    },
    {
        "id": 92,
        "key": "+39",
        "value": "Italy"
    },
    {
        "id": 93,
        "key": "+1876",
        "value": "Jamaica"
    },
    {
        "id": 94,
        "key": "+81",
        "value": "Japan"
    },
    {
        "id": 95,
        "key": "+962",
        "value": "Jordan"
    },
    {
        "id": 96,
        "key": "+7",
        "value": "Russia"
    },
    {
        "id": 97,
        "key": "+254",
        "value": "Kenya"
    },
    {
        "id": 98,
        "key": "+686",
        "value": "Kiribati"
    },
    {
        "id": 99,
        "key": "+850",
        "value": "Korea, North"
    },
    {
        "id": 100,
        "key": "+82",
        "value": "Korea, South"
    },
    {
        "id": 101,
        "key": "+965",
        "value": "Kuwait"
    },
    {
        "id": 102,
        "key": "+996",
        "value": "Kyrgyzstan"
    },
    {
        "id": 103,
        "key": "+856",
        "value": "Laos"
    },
    {
        "id": 104,
        "key": "+371",
        "value": "Latvia"
    },
    {
        "id": 105,
        "key": "+961",
        "value": "Lebanon"
    },
    {
        "id": 106,
        "key": "+266",
        "value": "Lesotho"
    },
    {
        "id": 107,
        "key": "+231",
        "value": "Liberia"
    },
    {
        "id": 108,
        "key": "+218",
        "value": "Libya"
    },
    {
        "id": 109,
        "key": "+423",
        "value": "Liechtenstein"
    },
    {
        "id": 110,
        "key": "+370",
        "value": "Lithuania"
    },
    {
        "id": 111,
        "key": "+352",
        "value": "Luxembourg"
    },
    {
        "id": 112,
        "key": "+853",
        "value": "Macao"
    },
    {
        "id": 113,
        "key": "+389",
        "value": "North Macedonia"
    },
    {
        "id": 114,
        "key": "+261",
        "value": "Madagascar"
    },
    {
        "id": 115,
        "key": "+265",
        "value": "Malawi"
    },
    {
        "id": 116,
        "key": "+60",
        "value": "Malaysia"
    },
    {
        "id": 117,
        "key": "+960",
        "value": "Maldives"
    },
    {
        "id": 118,
        "key": "+223",
        "value": "Mali"
    },
    {
        "id": 119,
        "key": "+356",
        "value": "Malta"
    },
    {
        "id": 120,
        "key": "+692",
        "value": "Marshall Islands"
    },
    {
        "id": 121,
        "key": "+596",
        "value": "Martinique"
    },
    {
        "id": 122,
        "key": "+222",
        "value": "Mauritania"
    },
    {
        "id": 123,
        "key": "+230",
        "value": "Mauritius"
    },
    {
        "id": 124,
        "key": "+262",
        "value": "Reunion"
    },
    {
        "id": 125,
        "key": "+52",
        "value": "Mexico"
    },
    {
        "id": 126,
        "key": "+691",
        "value": "Micronesia, Federated States Of"
    },
    {
        "id": 127,
        "key": "+373",
        "value": "Moldova"
    },
    {
        "id": 128,
        "key": "+377",
        "value": "Monaco"
    },
    {
        "id": 129,
        "key": "+976",
        "value": "Mongolia"
    },
    {
        "id": 130,
        "key": "+382",
        "value": "Montenegro"
    },
    {
        "id": 131,
        "key": "+1664",
        "value": "Montserrat"
    },
    {
        "id": 132,
        "key": "+212",
        "value": "Morocco"
    },
    {
        "id": 133,
        "key": "+258",
        "value": "Mozambique"
    },
    {
        "id": 134,
        "key": "+95",
        "value": "Myanmar"
    },
    {
        "id": 135,
        "key": "+264",
        "value": "Namibia"
    },
    {
        "id": 136,
        "key": "+674",
        "value": "Nauru"
    },
    {
        "id": 137,
        "key": "+977",
        "value": "Nepal"
    },
    {
        "id": 138,
        "key": "+31",
        "value": "Netherlands"
    },
    {
        "id": 139,
        "key": "+599",
        "value": "Netherlands Antilles"
    },
    {
        "id": 140,
        "key": "+687",
        "value": "New Caledonia"
    },
    {
        "id": 141,
        "key": "+64",
        "value": "New Zealand"
    },
    {
        "id": 142,
        "key": "+505",
        "value": "Nicaragua"
    },
    {
        "id": 143,
        "key": "+227",
        "value": "Niger"
    },
    {
        "id": 144,
        "key": "+234",
        "value": "Nigeria"
    },
    {
        "id": 145,
        "key": "+683",
        "value": "Niue"
    },
    {
        "id": 146,
        "key": "+1670",
        "value": "Northern Mariana Islands"
    },
    {
        "id": 147,
        "key": "+47",
        "value": "Norway"
    },
    {
        "id": 148,
        "key": "+968",
        "value": "Oman"
    },
    {
        "id": 149,
        "key": "+92",
        "value": "Pakistan"
    },
    {
        "id": 150,
        "key": "+680",
        "value": "Palau"
    },
    {
        "id": 151,
        "key": "+507",
        "value": "Panama"
    },
    {
        "id": 152,
        "key": "+675",
        "value": "Papua New Guinea"
    },
    {
        "id": 153,
        "key": "+595",
        "value": "Paraguay"
    },
    {
        "id": 154,
        "key": "+51",
        "value": "Peru"
    },
    {
        "id": 155,
        "key": "+63",
        "value": "Philippines"
    },
    {
        "id": 156,
        "key": "+48",
        "value": "Poland"
    },
    {
        "id": 157,
        "key": "+351",
        "value": "Portugal"
    },
    {
        "id": 158,
        "key": "+1787",
        "value": "Puerto Rico"
    },
    {
        "id": 159,
        "key": "+974",
        "value": "Qatar"
    },
    {
        "id": 160,
        "key": "+40",
        "value": "Romania"
    },
    {
        "id": 161,
        "key": "+250",
        "value": "Rwanda"
    },
    {
        "id": 162,
        "key": "+290",
        "value": "Saint Helena"
    },
    {
        "id": 163,
        "key": "+1869",
        "value": "Saint Kitts and Nevis"
    },
    {
        "id": 164,
        "key": "+1758",
        "value": "Saint Lucia"
    },
    {
        "id": 165,
        "key": "+508",
        "value": "Saint Pierre and Miquelon"
    },
    {
        "id": 166,
        "key": "+1784",
        "value": "Saint Vincent and the Grenadines"
    },
    {
        "id": 167,
        "key": "+685",
        "value": "Samoa"
    },
    {
        "id": 168,
        "key": "+378",
        "value": "San Marino"
    },
    {
        "id": 169,
        "key": "+239",
        "value": "Sao Tome and Principe"
    },
    {
        "id": 170,
        "key": "+966",
        "value": "Saudi Arabia"
    },
    {
        "id": 171,
        "key": "+221",
        "value": "Senegal"
    },
    {
        "id": 172,
        "key": "+381",
        "value": "Serbia"
    },
    {
        "id": 173,
        "key": "+248",
        "value": "Seychelles"
    },
    {
        "id": 174,
        "key": "+232",
        "value": "Sierra Leone"
    },
    {
        "id": 175,
        "key": "+65",
        "value": "Singapore"
    },
    {
        "id": 176,
        "key": "+421",
        "value": "Slovakia"
    },
    {
        "id": 177,
        "key": "+386",
        "value": "Slovenia"
    },
    {
        "id": 178,
        "key": "+677",
        "value": "Solomon Islands"
    },
    {
        "id": 179,
        "key": "+252",
        "value": "Somalia"
    },
    {
        "id": 180,
        "key": "+27",
        "value": "South Africa"
    },
    {
        "id": 181,
        "key": "+34",
        "value": "Spain"
    },
    {
        "id": 182,
        "key": "+94",
        "value": "Sri Lanka"
    },
    {
        "id": 183,
        "key": "+249",
        "value": "Sudan"
    },
    {
        "id": 184,
        "key": "+597",
        "value": "Suriname"
    },
    {
        "id": 185,
        "key": "+268",
        "value": "Swaziland"
    },
    {
        "id": 186,
        "key": "+46",
        "value": "Sweden"
    },
    {
        "id": 187,
        "key": "+41",
        "value": "Switzerland"
    },
    {
        "id": 188,
        "key": "+963",
        "value": "Syria"
    },
    {
        "id": 189,
        "key": "+886",
        "value": "Taiwan"
    },
    {
        "id": 190,
        "key": "+992",
        "value": "Tajikistan"
    },
    {
        "id": 191,
        "key": "+255",
        "value": "Tanzania"
    },
    {
        "id": 192,
        "key": "+66",
        "value": "Thailand"
    },
    {
        "id": 193,
        "key": "+670",
        "value": "Timor-Leste"
    },
    {
        "id": 194,
        "key": "+228",
        "value": "Togo"
    },
    {
        "id": 195,
        "key": "+690",
        "value": "Tokelau"
    },
    {
        "id": 196,
        "key": "+676",
        "value": "Tonga"
    },
    {
        "id": 197,
        "key": "+1868",
        "value": "Trinidad and Tobago"
    },
    {
        "id": 198,
        "key": "+216",
        "value": "Tunisia"
    },
    {
        "id": 199,
        "key": "+90",
        "value": "Türkiye"
    },
    {
        "id": 200,
        "key": "+993",
        "value": "Turkmenistan"
    },
    {
        "id": 201,
        "key": "+1649",
        "value": "Turks and Caicos Islands"
    },
    {
        "id": 202,
        "key": "+688",
        "value": "Tuvalu"
    },
    {
        "id": 203,
        "key": "+256",
        "value": "Uganda"
    },
    {
        "id": 204,
        "key": "+380",
        "value": "Ukraine"
    },
    {
        "id": 205,
        "key": "+971",
        "value": "United Arab Emirates"
    },
    {
        "id": 206,
        "key": "+44",
        "value": "United Kingdom"
    },
    {
        "id": 207,
        "key": "+598",
        "value": "Uruguay"
    },
    {
        "id": 208,
        "key": "+998",
        "value": "Uzbekistan"
    },
    {
        "id": 209,
        "key": "+678",
        "value": "Vanuatu"
    },
    {
        "id": 210,
        "key": "+3",
        "value": "Vatican"
    },
    {
        "id": 211,
        "key": "+58",
        "value": "Venezuela"
    },
    {
        "id": 212,
        "key": "+84",
        "value": "Vietnam"
    },
    {
        "id": 213,
        "key": "+1284",
        "value": "Virgin Islands, British"
    },
    {
        "id": 214,
        "key": "+1340",
        "value": "Virgin Islands, US"
    },
    {
        "id": 215,
        "key": "+681",
        "value": "Wallis and Futuna"
    },
    {
        "id": 216,
        "key": "+967",
        "value": "Yemen"
    },
    {
        "id": 217,
        "key": "+260",
        "value": "Zambia"
    },
    {
        "id": 218,
        "key": "+263",
        "value": "Zimbabwe"
    }
];
